@font-face {
    font-family: 'font';
    src: url('./font/font.eot?56664924');
    src: url('./font/font.eot?56664924#iefix') format('embedded-opentype'),
        url('./font/font.woff2?56664924') format('woff2'),
        url('./font/font.woff?56664924') format('woff'),
        url('./font/font.ttf?56664924') format('truetype'),
        url('./font/font.svg?56664924#font') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^='icon-']:before,
[class*=' icon-']:before {
    font-family: 'font';
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-conciliadores:before {
    content: '\e800';
} /* '' */
.icon-abajo:before {
    content: '\e801';
} /* '' */
.icon-admin:before {
    content: '\e802';
} /* '' */
.icon-tool_tip-33:before {
    content: '\e803';
} /* '' */
.icon-advertencia:before {
    content: '\e804';
} /* '' */
.icon-agregar:before {
    content: '\e805';
} /* '' */
.icon-arriba:before {
    content: '\e806';
} /* '' */
.icon-borrar:before {
    content: '\e807';
} /* '' */
.icon-buscar:before {
    content: '\e808';
} /* '' */
.icon-calendario:before {
    content: '\e809';
} /* '' */
.icon-cancelar:before {
    content: '\e80a';
} /* '' */
.icon-check:before {
    content: '\e80b';
} /* '' */
.icon-cliente:before {
    content: '\e80c';
} /* '' */
.icon-Menu:before {
    content: '\e80d';
} /* '' */
.icon-descargar:before {
    content: '\e80e';
} /* '' */
.icon-direccion:before {
    content: '\e80f';
} /* '' */
.icon-editar:before {
    content: '\e810';
} /* '' */
.icon-enviar:before {
    content: '\e811';
} /* '' */
.icon-establecimiento:before {
    content: '\e812';
} /* '' */
.icon-excel:before {
    content: '\e813';
} /* '' */
.icon-genererar_c:before {
    content: '\e814';
} /* '' */
.icon-imagen:before {
    content: '\e815';
} /* '' */
.icon-info:before {
    content: '\e816';
} /* '' */
.icon-izquierda:before {
    content: '\e817';
} /* '' */
.icon-mas_info:before {
    content: '\e812';
} /* '' */
.icon-opcion:before {
    content: '\e819';
} /* '' */
.icon-pagos:before {
    content: '\e81a';
} /* '' */
.icon-politicas:before {
    content: '\e81b';
} /* '' */
.icon-prefactura:before {
    content: '\e81c';
} /* '' */
.icon-vendedores:before {
    content: '\e81d';
} /* '' */
.icon-error:before {
    content: '\e81e';
} /* '' */
.icon-comercio:before {
    content: '\e81f';
} /* '' */
.icon-derecha:before {
    content: '\e820';
} /* '' */
.icon-excel2:before {
    content: '\e821';
} /* '' */
.icon-modal:before {
    content: '\e822';
} /* '' */
.icon-carga:before {
    content: '\e824';
} /* '' */
.icon-menu:before {
    content: '\e825';
} /* '' */
.icon-correo:before {
    content: '\2709';
} /* '' */
.icon-copy:before {
    content: '\e818';
} /* '' */
html {
    font-size: 15px;
}
body {
    font-family: 'Font-Core', 'Work Sans';
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: white;
    font-size: 14px;
}
body #root {
    height: 100%;
}
body #root #page-wrap {
    height: 100%;
}
a,
a:hover,
button,
button:hover,
button:focus {
    cursor: pointer;
    outline: medium none !important;
}

.valign-center {
    display: flex;
    align-items: center;
}

.promo-pagination {
    justify-content: center;
    flex: 0 0 100%;
    max-width: 100%;
}

.promo-pagination li {
    display: flex;
    justify-content: center;
    margin: 0 5px;
    width: 23px;
    height: 23px;
}
.promo-pagination li a {
    border: none;
    color: #808080;
}

.promo-pagination li:first-child a {
    border: none;
}
.promo-pagination li:last-child a {
    border: none;
}

.promo-pagination li.active {
    background-color: #dbe3fe;
    border-radius: 50%;
    font-weight: 500;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: none;
}

#page-wrap {
    overflow-x: hidden;
}

#page-wrap-public2 .main-panel {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#page-wrap-public2 .main-panel .container-fluid {
    flex-grow: 1;
    overflow-y: auto;
}

@media screen and (max-width: 767px) {
    #page-wrap {
        flex-direction: column;
    }
    .container {
        max-width: unset;
    }
}

@media screen and (min-width: 768px) {
    .promo-pagination {
        margin-top: 10px;
    }
}

@media screen and (min-width: 992px) {
    .promo-pagination li {
        width: 24px;
        height: 24px;
        margin: 0 6px;
    }

    .promo-pagination li a {
        font-size: 17px;
    }

    .promo-pagination li:first-child a {
        margin-top: 2px;
    }

    .promo-pagination li:last-child a {
        margin-top: 2px;
    }
}

@media screen and (min-width: 1200px) {
    .container {
        max-width: 960px !important;
    }
}

@media screen and (min-width: 1440px) {
    .container {
        max-width: 1140px !important;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1439px) {
    body.ReactModal__Body--open .ReactModalPortal .overlay .content {
        max-width: 467px;
        height: 400px;
    }
}

body.ReactModal__Body--open .ReactModalPortal .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 100;
}

body.ReactModal__Body--open .ReactModalPortal .overlay .content {
    position: relative;
    outline: none;
    padding: 15px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255) none repeat scroll 0% 0%;
    overflow: hidden;
    border-radius: 4px;
    max-width: 600px;
    width: 100%;
    height: 543px;
    text-align: center;
    z-index: 100;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
}
